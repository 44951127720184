import { memo, useState } from 'react';

import { ClassName } from '../../../../types';
import {
  FileAttachmentID,
  FileAttachmentUUID
} from '../../fileAttachmentsTypes';
import { IconsEnum } from '../../../../assets/icons/types';

import { FileAttachmentWhiteboardInfo } from './components/FileAttachmentWhiteboardInfo';

import { Icon } from '../../../../helpers/Icon';
import { Tooltip } from '../../../../helpers/tooltips/Tooltip';

import {
  bodyTooltipContainer,
  TooltipPlacement
} from '../../../../helpers/tooltips/tooltipsConstants';

interface FileAttachmentWhiteboardTooltipIconProps {
  className?: ClassName;
  fileAttachmentId: FileAttachmentID | FileAttachmentUUID;
}
function FileAttachmentWhiteboardTooltipIcon({
  className,
  fileAttachmentId
}: FileAttachmentWhiteboardTooltipIconProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  return (
    <span
      className={
        className ||
        'p-1 rounded bg-black bg-opacity-50 text-white pointer-events-auto'
      }
      ref={setReferenceTooltipElement}
    >
      <Icon icon={IconsEnum.WHITEBOARD_SOLID} />

      <Tooltip
        appendTo={bodyTooltipContainer}
        interactive
        lazy
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
        withArrow
      >
        <FileAttachmentWhiteboardInfo fileAttachmentId={fileAttachmentId} />
      </Tooltip>
    </span>
  );
}

export default memo<FileAttachmentWhiteboardTooltipIconProps>(
  FileAttachmentWhiteboardTooltipIcon
);
